@import "../../assets/css/colors.scss";

.abrasive_solutions {
    position: relative;
    margin-bottom: 10rem;

    @media(max-width:991px) {
        margin-bottom: 0;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 56.9rem;
        background-color: $black-color;
        border-radius: 2.4rem;

        @media(max-width:991px) {
            height: 100%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 56.9rem;
        background-image: url(../../assets/images/abrasive_solutions-bg.png);
        border-radius: 2.4rem;
        opacity: 0.15;

        @media(max-width:991px) {
            height: 100%;
        }
    }

    .abrasive_solutions_contnt {
        position: relative;
        padding-top: 10rem;

        @media(max-width:991px) {
            padding-top: 7rem;
            padding-bottom: 7rem;
        }

        @media(max-width:575px) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }

        .des_text {
            font-size: 1.9rem;
            line-height: 3.4rem;

            @media(max-width:1650px) {
                line-height: 1.9;
                font-size: 1.7rem;
            }

            @media(max-width:767px) {
                font-size: 1.6rem;
                line-height: 2.8rem;
                margin-bottom: 0;
            }
        }

        .abrasive_solutions_btm {
            margin-top: 4rem;

            @media(max-width:1199px) {
                margin-top: 0;
            }

            .abrasive_solutions_itm {
                margin-top: 2.7rem;
                position: relative;
                height: 47.5rem;
                background-color: $white-color;
                z-index: 9;
                border-radius: 2.4rem;
                overflow: hidden;
                padding: 3rem 3.8rem;

                @media(max-width:1599px) {
                    height: 42.5rem;
                }

                @media(max-width:1199px) {
                    padding: 3rem 2.5rem;
                    height: 35.5rem;
                }

                @media(max-width:1199px) {
                    padding: 3rem 2.5rem;
                    height: 35.5rem;
                }

                .abrasive_solutions_itm_contnt {
                    position: relative;
                    z-index: 99;

                    h3 {
                        font-size: 2.8rem;
                        color: $black-color;
                        margin-bottom: 1.8rem;

                        @media(max-width:1599px) {
                            font-size: 2.4rem;
                        }

                        @media(max-width:1199px) {
                            font-size: 2rem;
                        }

                        span {
                            color: $primary-color;
                        }
                    }

                    p {
                        color: $text-color;
                        font-size: 1.7rem;
                        line-height: 2.6rem;
                        margin-bottom: 2.4rem;

                        @media(max-width:1199px) {
                            font-size: 1.6rem;
                            line-height: 2.5rem;
                            margin-bottom: 2rem;
                        }
                    }

                    a {
                        font-size: 1.7rem;
                        color: $primary-color;
                        position: relative;
                        display: inline-block;
                        font-weight: 600;
                        padding-right: 60px;

                        &:hover {
                            color: $secondary-color;
                        }

                        span {
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }

                .abrasive_solutions_itm_img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    bottom: 0;
                    height: 100%;
                    object-fit: cover;

                    @media(max-width:767px) {
                        opacity: 0.25;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: transparent linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #F7F7F7 43%, #80808000 100%) 0% 0% no-repeat padding-box;
                    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 43%, #80808000 100%) 0% 0% no-repeat padding-box;
                    z-index: 9;
                }
            }
        }
    }
}