@import "../../assets/css/colors.scss";

.our_clients {
    background-color: $secondary-color;
    padding-top: 2.5rem;
    padding-bottom: 6rem;
    position: relative;

    @media(max-width:1199px) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    @media(max-width:991px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    @media(max-width:575px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/images/our-clients-bg.png);
        opacity: 0.13;
        background-size: cover;
    }

    .our_clients_contnt {
        position: relative;
        z-index: 99;

        h2 {
            margin-top: 2rem;
            margin-bottom: 2.8rem;
        }

        p {
            max-width: 85%;

            @media(max-width:1199px) {
                max-width: 100%;
            }
        }

        .our_clients_right_row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -1.8rem;
            margin-left: -1.8rem;

            @media(max-width:991px) {
                margin-right: -1.5rem;
                margin-left: -1.5rem;
            }

            @media(max-width:575px) {
                justify-content: center;
            }

            .our_clients_right_row_col {
                padding-left: 1.8rem;
                padding-right: 1.8rem;
                width: 33.33%;

                @media(max-width:991px) {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }

                @media(max-width:575px) {
                    width: 85%;
                }
            }

            .our_clients_itm {
                background-color: $white-color;
                border-radius: 2rem;
                padding: 7rem 4rem 4rem 4rem;
                text-align: center;
                margin-top: 3.5rem;

                @media(max-width:1650px) {
                    padding: 5rem 2.5rem 2.5rem 2.5rem;
                }

                @media(max-width:1399px) {
                    padding: 5rem 2rem 2rem 2rem;
                }

                @media(max-width:991px) {
                    padding-bottom: 3rem;
                }

                @media(max-width:767px) {
                    padding: 5rem 1rem 1.5rem 1rem;
                    border-radius: 1rem;
                }

                @media(max-width:575px) {
                    margin-top: 3rem;
                }

                img {
                    max-width: 16.5rem;
                    width: 100%;

                    @media(max-width:991px) {
                        max-width: 10.5rem;
                        margin-bottom: 2rem;
                    }
                }

                p {
                    color: $text-color;
                    font-size: 1.9rem;
                    margin-top: 7.3rem;
                    font-weight: 400;
                    max-width: 100%;

                    @media(max-width:1650px) {
                        margin-top: 5rem;
                    }

                    @media(max-width:1399px) {
                        margin-top: 3rem;
                    }

                    @media(max-width:767px) {
                        font-size: 1.6rem;
                    }
                }
            }

            .our_clients_itm_tp {
                padding-top: 5rem;

                @media(max-width:575px) {
                    padding-top: 0;
                }
            }
        }
    }
}