@import "../../assets/css/colors.scss";

.home_banner {
    position: relative;
    background-color: $white-color;
    padding-top: 21.8rem;
    padding-bottom:10rem;

    @media(max-width:991px) {
        padding-bottom: 7rem;
    }
    @media(max-width:767px) {
        padding-top: 15rem;
    }
    @media(max-width:575px) {
        padding-bottom:5rem;
    }

    .home_banner_contnt {
        position: relative;
        z-index: 9;

        h1 {
            font-size: 7rem;
            color: $black-color;

            @media(max-width:1399px) {
                font-size: 6.2rem;
                line-height: 1.5;
            }

            @media(max-width:991px) {
                font-size: 5.2rem;
            }

            @media(max-width:767px) {
                font-size: 4rem;
            }

            @media(max-width:575px) {
                font-size: 3.6rem;
            }
        }

        p {
            font-size: 2.2rem;
            margin-top: 3.5rem;
            margin-bottom: 8.5rem;

            @media(max-width:1399px) {
                margin-bottom: 5.5rem;
            }

            @media(max-width:767px) {
                font-size: 2rem;
                margin-bottom: 3.5rem;
            }
        }

        img {
            width: 100%;
            max-width: 109.8rem;
        }
    }

    .home_banner_bg_img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.05;
    }
}