// @import url('assets/css/fonts.scss');
@import url('assets/css/style.scss');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  scroll-behavior: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  font-weight: 300;

  &.stop-scroll {
    overflow: hidden;
  }
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}