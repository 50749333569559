@import "../../assets/css/colors.scss";

.experience_unmatched {
    background-color: $white-color;
    padding-top: 12.6rem;
    padding-bottom: 12rem;

    @media(max-width:991px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    @media(max-width:575px) {
        padding-bottom: 5rem;
    }

    .experience_img_sec {
        position: relative;
        display: inline-block;

        @media(max-width:991px) {
            display: block;
            margin-bottom: 2.5rem;
        }

        .experience_img {
            position: relative;
            width: 100%;
            max-width: 66.8rem;
        }

        .experience_img_bg {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;
            opacity: 0.05;
            max-width: 53rem;
            height: 60.8rem;
            transform: translate(-50%, -50%);

            @media(max-width:991px) {
                height: auto;
            }
        }
    }
}