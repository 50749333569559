@import "../../assets/css/colors.scss";

.subscribe {
    margin-bottom: 10rem;

    @media(max-width:991px) {
        margin-bottom: 7rem;
    }

    @media(max-width:575px) {
        margin-bottom: 5rem;
    }

    h4 {
        color: $secondary-color ;
        font-size: 2.8rem;
        font-weight: 600;
        margin-bottom: 2rem;

        @media(max-width:1599px) {
            font-size: 2.4rem;
        }
    }

    h2 {
        margin-bottom: 3rem !important;
    }

    p {
        margin-bottom: 0;
    }

    form {
        max-width: 96rem;
        height: 9rem;
        background-color: $white-color;
        box-shadow: 0 0 30px 0 rgb(0 0 0 / 7%);
        border-radius: 10rem;
        display: flex;
        align-items: center;
        padding: 1.2rem;
        margin-top: 6.7rem;

        @media(max-width:1599px) {
            margin-top: 5rem;
            height: 7.2rem;
        }

        @media(max-width:575px) {
            margin-top: 2rem;
            height: 6.2rem;
        }

        input {
            font-size: 2.3rem;
            line-height: 3.4rem;
            border: none !important;
            box-shadow: none !important;
            padding-left: 4rem;
            background-color: transparent !important;

            @media(max-width:1599px) {
                font-size: 2rem;
                line-height: 3rem;
            }

            @media(max-width:991px) {
                padding-left: 2rem;
                color: $black-color;
            }

            @media(max-width:575px) {
                font-size: 1.6rem !important;
                padding-left: 1rem;
            }
        }

        button {
            min-width: 21.6rem !important;
            height: 6.6rem;
            font-size: 2.3rem !important;
            border-radius: 10rem !important;

            @media(max-width:1599px) {
                font-size: 2rem !important;
                height: 5rem;
                min-width: 17rem !important;
            }

            @media(max-width:575px) {
                font-size: 1.6rem !important;
                height: 4rem;
                min-width: 9rem !important;
                line-height: 5px !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}