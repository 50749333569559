@import "../../assets/css/colors.scss";

.industry {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media(max-width:991px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    @media(max-width:575px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media(max-width:991px) {
            margin-right: -1rem;
            margin-left: -1rem;
        }

        &.industry_ul_frst {
            li {
                &:nth-child(even) {
                    margin-top: 10rem;

                    .industry_ul_itm {
                        border: 0.1rem solid $secondary-color;

                        .industry_itm_img {
                            background-color: $secondary-color;
                        }
                    }
                }

                @media(max-width:1199px) {
                    &:nth-child(even) {
                        margin-top: 6.5rem;
                    }
                }

                @media(max-width:991px) {
                    &:nth-child(even) {
                        margin-top: 4rem;
                    }
                }
            }
        }

        li {
            margin-top: 5.5rem;

            @media(max-width:1199px) {
                margin-top: 4rem;
            }

            @media(max-width:991px) {
                width: 50%;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            @media(max-width:575px) {
                width: 100%;
            }

            .industry_ul_itm {
                border: 0.1rem solid $primary-color;
                padding: 3rem;
                position: relative;
                border-radius: 2.4rem;
                padding-left: 14.2rem;

                @media(max-width:1650px) {
                    padding-left: 12rem;
                }

                @media(max-width:1399px) {
                    padding-left: 9rem;
                }

                @media(max-width:1199px) {
                    border-radius: 1.2rem;
                    padding: 2rem;
                    padding-left: 7rem;
                }

                @media(max-width:991px) {
                    padding-left: 8rem;
                }

                @media(max-width:575px) {
                    padding-left: 10rem;
                }

                .industry_itm_img {
                    display: inline-block;
                    position: absolute;
                    width: 11.2rem;
                    height: 11.2rem;
                    background-color: $primary-color;
                    border-radius: 50%;
                    left: 1rem;
                    top: -5.6rem;
                    text-align: center;
                    line-height: 11.2rem;

                    @media(max-width:1650px) {
                        width: 9.2rem;
                        height: 9.2rem;
                        top: -4.6rem;
                        line-height: 9.2rem;
                    }

                    @media(max-width:1399px) {
                        width: 8.2rem;
                        height: 8.2rem;
                        top: -4.1rem;
                        line-height: 8.2rem;

                        img {
                            width: 5rem;
                        }
                    }

                    @media(max-width:1199px) {
                        width: 7.2rem;
                        height: 7.2rem;
                        top: -4.1rem;
                        line-height: 7.2rem;

                        img {
                            width: 4rem;
                        }
                    }

                    @media(max-width:991px) {
                        width: 6.2rem;
                        height: 6.2rem;
                        top: -3.1rem;
                        line-height: 6.2rem;
                    }

                    @media(max-width:575px) {
                        width: 7.5rem;
                        height: 7.5rem;
                        top: -2.75rem;
                        line-height: 7.5rem;
                    }
                }

                .industry_itm_txt {
                    font-size: 2.3rem;
                    font-weight: 600;
                    color: $black-color;

                    @media(max-width:1650px) {
                        font-size: 2rem;
                    }

                    @media(max-width:1399px) {
                        font-size: 1.8rem;
                    }

                    @media(max-width:1199px) {
                        font-size: 1.4rem;
                    }

                    @media(max-width:575px) {
                        font-size: 1.8rem;
                    }

                }
            }
        }

        &.industry_ul_scnd {
            @media(max-width:991px) {
                margin-top: 3.5rem;
            }
            @media(max-width:575px) {
                margin-top:0;
            }

            li {
                .industry_ul_itm {

                    min-width: 16rem;
                }

                &:nth-child(odd) {
                    .industry_ul_itm {
                        border: 0.1rem solid $secondary-color;

                        .industry_itm_img {
                            background-color: $secondary-color;
                        }
                    }
                }

                @media(max-width:575px) {
                    &:nth-child(odd) {
                        .industry_ul_itm {
                            border-color: $primary-color;

                            .industry_itm_img {
                                background-color: $primary-color;
                            }
                        }
                    }
                }

                &:nth-child(even) {
                    margin-top: 9.3rem;
                }


                @media(max-width:1199px) {
                    &:nth-child(even) {
                        margin-top: 6.3rem;
                    }
                }

                @media(max-width:991px) {
                    &:nth-child(even) {
                        margin-top: 4rem;
                    }
                }
                @media(max-width:575px) {
                    &:nth-child(even) {
                        .industry_ul_itm {
                            border-color: $secondary-color;

                            .industry_itm_img {
                                background-color: $secondary-color;
                            }
                        }
                    }
                }


                &:last-child {
                    .industry_ul_itm {
                        min-width: 30.2rem
                    }
                }

                @media(max-width:1199px) {
                    &:last-child {
                        .industry_ul_itm {
                            min-width: auto;
                        }
                    }
                }
            }
        }
    }
}