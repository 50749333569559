@import "./../../assets//css/colors.scss";

.hmg-header {
    .navbar {
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;

        @media(max-width:1499px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        @media(max-width:991px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .navbar-brand {
            img {
                @media(max-width:991px) {
                    width: 10rem;
                }
            }
        }

        .navbar-toggler {
            border: none;
            height: 5rem;
            padding: 0 .6rem;
            background-color: transparent !important;
            width: 5rem;
            position: relative;
            z-index: 999;
            box-shadow: none;
            outline: none !important;
            box-shadow: none !important;

            span {
                background-image: none;
                width: 3.6rem;
                height: .3rem;
                background: $primary-color;
                display: block;
                opacity: 1;
                transition: all .2s cubic-bezier(.98, .57, 1, 1) 0s;
                margin: .6rem 0 0;
                border-radius: 1rem;
                position: absolute;
                top: 1.8rem;

                &::before {
                    content: "";
                    position: absolute;
                    width: 3rem;
                    height: .3rem;
                    background: $primary-color;
                    display: block;
                    opacity: 1;
                    transition: all .2s cubic-bezier(.98, .57, 1, 1) 0s;
                    left: 0;
                    border-radius: 10rem;
                    top: -1.2rem;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 3rem;
                    height: .3rem;
                    background: $primary-color;
                    display: block;
                    opacity: 1;
                    transition: all .2s cubic-bezier(.98, .57, 1, 1) 0s;
                    left: 0;
                    border-radius: 10rem;
                    bottom: -1.2rem;
                }
            }

            &.open {
                span {
                    transform: rotate(45deg);
                    width: 3.6rem;

                    &::before {
                        transform: rotate(-90deg);
                        top: 0;
                        width: 3.6rem;
                    }

                    &::after {
                        opacity: 0;
                    }
                }
            }
        }

        .navbar-nav {
            &.hmg-header-left {
                .nav-link {
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: 2.9rem;
                    margin-right: 2.9rem;
                    font-size: 2.2rem;
                    padding-top: 0;
                    padding-bottom: 0;
                    color: $black-color;
                    position: relative;
                    border-bottom: 0.15rem solid transparent;

                    &:hover {
                        color: $primary-color;
                        border-color: $primary-color;
                    }

                    @media(max-width:991px) {
                        padding: 1.5rem 0 1.5rem 2rem;
                        border-bottom: 0.1rem solid #e7e7e7;
                        margin: 0;
                        text-align: left;
                        font-size: 2rem;
                        font-weight: 500;
                    }
                }
            }



            .hmg-header-right {
                position: relative;

                @media(max-width:991px) {
                    padding: 1.5rem 0 1.5rem 2rem;
                    border-bottom: 0.1rem solid #e7e7e7;
                    margin: 0;
                    text-align: left;
                    font-size: 2rem;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                }

                .theme-btn {
                    min-width: 22.6rem;
                    height: 5.6rem;
                    text-align: left;
                    line-height: 2.4rem;
                    padding-left: 4.4rem;

                    @media(max-width:991px) {
                        min-width: auto;
                        line-height: 1.6rem;
                        height: 4.6rem;
                        padding-left: 4.5rem;
                        padding-right: 4.5rem;
                    }

                    @media(max-width:575px) {
                        padding-left: 3.5rem;
                        padding-right: 3.5rem;
                    }
                }

                span {
                    position: absolute;
                    right: 0;
                    top: -0.6rem;
                    background: $white-color;
                    width: 6.6rem;
                    height: 6.6rem;
                    border-radius: 50%;
                    display: inline-block;

                    @media(max-width:991px) {
                        position: static;
                        height: auto;
                    }

                    a {
                        display: inline-block;
                        width: 5.6rem;
                        background: $primary-color;
                        height: 5.6rem;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 5.6rem;
                        margin-left: 1rem;
                        margin-top: 0.5rem;
                        color: $white-color;
                        font-size: 2.2rem;

                        @media(max-width:991px) {
                            margin-top: 0;
                            margin-left: 1rem;


                            width: 4.6rem;
                            height: 4.6rem;
                            line-height: 4.6rem;
                            font-size: 2.2rem;
                        }

                        &:hover {
                            background-color: $secondary-color !important;
                        }
                    }
                }
            }

            .social-lnks-hdr {
                a {
                    color: $text-color;
                    font-size: 2rem;
                    width: 4rem;
                    height: 4rem;
                    display: block;
                    text-align: center;
                    line-height: 3.5rem;
                    border: 0.1rem solid $text-color;
                    border-radius: .5rem;
                    margin: 0 0.5rem;

                    &:hover {
                        background-color: $secondary-color;
                        color: $white-color;
                        border-color: $secondary-color;
                    }
                }
            }
        }
    }

    &.is-sticky {
        background-color: $white-color;
        box-shadow: 0 0.3rem 0.7rem rgba(0, 0, 0, 0.15);
        animation: headerSlideDown 0.95s ease 0s 1 normal forwards running;

        .navbar {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            .navbar-brand {
                img {
                    width: 12.5rem;

                    @media(max-width:991px) {
                        width: 10rem;
                    }
                }
            }
        }
    }

    .navbar-collapse {
        @media(max-width:991px) {
            position: fixed;
            top: 0;
            right: -40rem;
            width: 32rem;
            height: 100vh;
            max-height: 100vh;
            background: $white-color;
            text-align: center;
            padding: 13rem 0 3rem;
            box-shadow: -3rem 0 3rem rgba(0, 0, 0, .1);
            transition: .5s;
            opacity: 0;
            z-index: 100;
            overflow: hidden;
            overflow-y: auto;

            &.show {
                right: 0;
                opacity: 1;
                z-index: 100;
            }
        }

        @media(max-width:575px) {
            padding: 12rem 0 3rem;
            width: 25rem;
        }
    }
}

@keyframes headerSlideDown {
    0% {
        margin-top: -15rem;
    }

    to {
        margin-top: 0;
    }
}