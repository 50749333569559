@import "../../assets/css/colors.scss";

.privacy_content {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media(max-width:991px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    @media(max-width:575px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    h2 {
        font-size: 3.6rem;
        margin-bottom: 1.5rem;
    }

    p {
        margin-bottom: 1.5rem;
    }

    h3 {
        font-size: 2.8rem;
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
    }

    ul {
        padding-left: 2rem;
        padding-top: 2rem;

        li {
            list-style-type: disc;
            margin-bottom: 1.5rem;

        }
    }
}