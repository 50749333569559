@import "../../assets/css/colors.scss";

.type_settings {
    margin-bottom: 10rem;

    @media(max-width:991px) {
        margin-bottom: 7rem;
    }

    @media(max-width:575px) {
        margin-bottom: 5rem;
    }

    h2 {
        margin-top: 7rem;

        @media(max-width:1399px) {
            margin-top: 0;
        }
    }

    p {
        margin-bottom: 0 !important;
    }

    .type_settings_itm {
        padding: 6rem 2rem 3.3rem 2rem;
        border: 0.7rem solid $brdr-clr;
        border-radius: 2.4rem;
        position: relative;
        margin-top: 6.4rem;

        .itm_img {
            display: inline-block;
            position: absolute;
            left: 2.9rem;
            top: -4.5rem;
            width: 9rem;
            height: 9rem;
            border: 0.7rem solid $brdr-clr;
            text-align: center;
            line-height: 7.6rem;
            background-color: $white-color;
            border-radius: 50%;

            @media(max-width:767px) {
                left: 2rem;
                top: -4rem;
                width: 8rem;
                height: 8rem;
                border-width: 0.5rem;
                line-height: 6.6rem;
            }
        }

        @media(max-width:767px) {
            padding: 4.5rem 1.5rem 1.5rem 1.5rem;
            border-radius: 1.4rem;
        }

        h4 {
            font-size: 2rem;
            margin-bottom: 1.8rem;
            line-height: 3rem;

            @media(max-width:1650px) {
                font-size: 1.8rem;
                margin-bottom: 1.5rem;
                line-height: 2.8rem;
            }
        }

        p {
            font-size: 1.5rem;
            line-height: 2.6rem;
            color: $text-color;
        }

    }
}