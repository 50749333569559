@import "../../assets/css/colors.scss";

.cta_section {
    background-image: url(../../assets/images/cta-bg.png);
    padding-top: 10.4rem;
    padding-bottom: 14.3rem;
    background-size: 100% 100%;
    margin-top: 10rem;
    margin-bottom: 7.5rem;

    @media(max-width:1650px) {
        padding-top: 8.4rem;
        padding-bottom: 8.4rem;
    }

    @media(max-width:1199px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
        background-image: none;
        background: transparent linear-gradient(129deg, #FB9F08 0%, #1E266D 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 1.2rem;

    }

    @media(max-width:991px) {
        margin-top: 7rem;
        margin-bottom: 7rem;
        text-align: center;
    }

    @media(max-width:575px) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    h2 {
        font-size: 3.5rem;
        line-height: 6.4rem;

        @media(max-width:1650px) {
            font-size: 3.2rem;
            line-height: 4.4rem;
        }

        @media(max-width:575px) {
            font-size: 2.8rem;
            line-height: 1.2;
        }
    }

    p {
        font-size: 1.9rem;
        line-height: 3.4rem;

        @media(max-width:1650px) {
            line-height: 1.9;
            font-size: 1.7rem;
        }
    }
}