@import "../../assets/css/colors.scss";

.home_banner {
    position: relative;
    background-color: $white-color;
    padding-top: 21.8rem;
    padding-bottom: 4.1rem;

    @media(max-width:767px) {
        padding-top: 15rem;
        padding-bottom: 4rem;
    }

    .home_banner_contnt {
        position: relative;
        z-index: 9;

        h1 {
            font-size: 7rem;
            color: $black-color;

            @media(max-width:1399px) {
                font-size: 6.2rem;
                line-height: 1.5;
            }

            @media(max-width:991px) {
                font-size: 5.2rem;
            }

            @media(max-width:767px) {
                font-size: 4rem;
            }

            @media(max-width:575px) {
                font-size: 3.6rem;
            }

            span {
                font-size: 7rem;
                color: $white-color;
                background-color: $secondary-color;
                line-height: 7rem;
                display: inline-block;
                padding: 0.35rem 1.5rem 1.15rem 1.5rem;
                font-weight: 600;

                @media(max-width:767px) {
                    font-size: 6.2rem;
                    line-height: 6.2rem;
                    padding-bottom: 0.7rem;
                }

                @media(max-width:575px) {
                    font-size: 4.2rem;
                    line-height: 4.2rem;
                    padding-bottom: 0.4rem;
                    letter-spacing:1.5px;
                }
            }
        }

        p {
            font-size: 2.2rem;
            margin-top: 3.5rem;
            margin-bottom: 8.5rem;

            @media(max-width:1399px) {
                margin-bottom: 5.5rem;
            }

            @media(max-width:767px) {
                font-size: 2rem;
                margin-bottom: 3.5rem;
            }
        }

        img {
            width: 100%;
            max-width: 109.8rem;
        }
    }

    .home_banner_bg_img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.05;
    }

    .down_arrow_icn {
        position: absolute;
        width: 6.2rem;
        display: inline-block;
        height: 6.2rem;
        border: 0.1rem solid #E2E2E2;
        text-align: center;
        line-height: 5.4rem;
        border-radius: 50%;
        font-size: 3.6rem;
        color: $black-color;
        left: 50%;
        transform: translate(-50%);
        bottom: -3.1rem;
        background-color: $white-color;
    }
}