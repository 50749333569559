@import "./colors.scss";

.hmg_footer {
    background-color: $text-color;
    padding-top: 6rem;
    padding-bottom: 2.8rem;

    @media(max-width:991px) {
        padding-top: 3rem;
        padding-bottom: 2rem;
    }

    .hmg_footer_innr {
        padding-top: 4rem;

        @media(max-width:991px) {
            padding-top: 3rem;
        }

        .footer_logo {
            margin-bottom: 3rem;

            @media(max-width:1199px) {
                width: 15rem;
            }

            @media(max-width:1199px) {
                margin-bottom: 2rem;
            }
        }

        p {
            font-size: 2rem;
            color: $white-color;

            @media(max-width:1199px) {
                font-size: 1.6rem;
            }

            a {
                font-size: 2rem;

                @media(max-width:1199px) {
                    font-size: 1.6rem;
                }
            }
        }

        .footer_social_links {
            margin-top: 2.8rem;

            @media(max-width:1199px) {
                margin-top: 2rem;
            }

            a {
                background-color: $primary-color !important;
                display: inline-block;
                width: 4rem;
                height: 4rem;
                border: 0.1rem solid $white-color;
                color: $white-color;
                border-radius: 50%;
                text-align: center;
                line-height: 3.6rem;
                margin-right: 2.1rem;

                @media(max-width:575px) {
                    margin-right: 1.5rem;
                }

                &:hover {
                    background-color: $secondary-color !important;
                }
            }
        }

        h4 {
            font-size: 2.5rem;
            color: $white-color;
            font-weight: 600;
            margin-bottom: 4.4rem;

            @media(max-width:1199px) {
                font-size: 2rem;
                margin-bottom: 2.5rem;
            }

            @media(max-width:767px) {
                margin-bottom: 1.5rem;
            }
        }

        ul {
            column-count: 2;

            li {
                margin-bottom: 1rem;

                a {
                    font-size: 2rem;
                    color: $white-color;
                    display: inline-block;

                    @media(max-width:1199px) {
                        font-size: 1.6rem;
                    }

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }

        .hmg_footer_innr_right {
            max-width: 39.5rem;
            margin-left: auto;

            @media(max-width:1599px) {
                max-width: 35rem;
            }

            @media(max-width:991px) {
                max-width: 100%;
            }
        }


        .book_now_btn {
            background-color: $white-color;
            font-size: 1.7rem;
            width: 100%;
            display: block;
            height: 5.6rem;
            line-height: 5.6rem;
            border-radius: 0.6rem;
            text-align: center;
            text-transform: uppercase;
            margin-top: 5rem;

            @media(max-width:1199px) {
                margin-top: 3rem;
            }

            @media(max-width:991px) {
                max-width: 25rem;
            }

            @media(max-width:575px) {
                margin-top: 2rem;
            }

            &:hover {
                background-color: $primary-color !important;
                color: $white-color !important;
            }
        }
    }

    .copy_right_text {
        padding-top: 7rem;

        @media(max-width:1599px) {
            padding-top: 5rem;
        }

        @media(max-width:991px) {
            padding-top: 3rem;
        }

        @media(max-width:575px) {
            padding-top: 2rem;
        }

        p {
            a {
                &:hover {
                    color: $primary-color;
                }
                strong{
                    font-weight:700;
                }
            }
        }
    }
}