@import "./colors.scss";

.container-fluid,
.container,
.container-lg {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.full-container {
    padding-left: 16rem;
    padding-right: 16rem;
    width: 100%;

    @media(max-width:1699px) {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    @media(max-width:1499px) {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    @media(max-width:1399px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.container {
    @media (min-width: 1700px) {
        max-width: 1470px;
    }

    @media (max-width: 1699px) {
        max-width: 1340px;
    }

    @media (max-width: 1599px) {
        max-width: 1260px;
    }

    @media (max-width: 1440px) {
        max-width: 1220px;
    }

    @media (max-width: 1400px) {
        max-width: 1200px;
    }

    @media (max-width: 1366px) {
        max-width: 1140px;
    }

    @media (max-width: 1199px) {
        max-width: 1000px;
    }

    @media (max-width: 1024px) {
        max-width: 920px;
    }

    @media (max-width: 991px) {
        max-width: 720px;
    }

    @media (max-width: 767px) {
        max-width: 540px;
    }

    @media (max-width: 575px) {
        max-width: 100%;
    }
}

.container-lg {
    @media (min-width: 576px) {
        max-width: 620px;
    }

    @media (min-width: 768px) {
        max-width: 800px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1024px) {
        max-width: 1020px;
    }

    @media (min-width: 1200px) {
        max-width: 1160px;
    }

    @media (min-width: 1366px) {
        max-width: 1300px;
    }

    @media (min-width: 1440px) {
        max-width: 1400px;
    }

    @media (min-width: 1600px) {
        max-width: 1542px;
    }

    @media (min-width: 1700px) {
        max-width: 1600px;
    }
}

.row {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
}

.row>* {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

a {
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    text-decoration: none !important;
    color: inherit;
}

a:focus,
a:hover,
button:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

button:active {
    color: inherit;
    background-color: transparent !important;
}

li,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

body {
    &.menu-open-body {
        overflow: hidden;
    }
}

/*----- common-css ------*/
.pdtb {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media (max-width: 991px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

.theme-btn.btn {
    background-color: $primary-color !important;
    color: $white-color !important;
    font-size: 1.8rem;
    line-height: 2.1rem;
    padding: 1.45rem 2.5rem;
    border: none;
    position: relative;
    display: inline-block;
    font-weight: 300;
    min-width: 19.6rem;
    border-radius: 3rem;

    @media (max-width: 991px) {
        font-size: 1.6rem;
        line-height: 2.1rem;
        padding: 1.25rem 1.5rem;
        min-width: 15rem;
    }

    &:hover {
        background-color: $secondary-color !important;
    }

    &:active {
        background-color: $secondary-color !important;
    }
}

.sub-heading {
    h2 {
        font-size: 4.3rem;
        color: $black-color;
        line-height: 1.16;
        margin-bottom: 4.5rem;

        @media (max-width: 1650px) {
            font-size: 3.6rem;
        }

        @media (max-width:1199px) {
            margin-bottom: 2.5rem;
        }

        @media (max-width: 767px) {
            font-size: 3.2rem;
        }

        @media (max-width: 575px) {
            font-size: 2.8rem;
            line-height: 1.2;
        }

        span {
            color: $primary-color;
        }
    }

    p {
        font-size: 1.9rem;
        color: $text-color;
        line-height: 2;
        margin-bottom: 2.7rem;

        @media (max-width: 1650px) {
            margin-bottom: 2rem;
            line-height: 1.9;
            font-size: 1.7rem;
        }

        @media (max-width: 767px) {
            font-size: 1.6rem;
            line-height: 2.8rem;
        }
    }
}

/*----- common-css ------*/

// Swal Css
.swal2-popup.swal2-modal.swal2-show {
    width: 55em;

    .swal2-title {
        p {
            font-size: 3.6rem;
            color: green;
        }
    }

    .swal2-html-container {
        p {
            font-size: 2rem;
            color: $black-color;
            line-height: 3rem;
        }
    }

    .swal2-actions {
        .swal2-confirm {
            &.swal2-styled {
                background-color: $prmry-light-clr !important;
                color: $white-color !important;
                font-size: 1.8rem;
                line-height: 2.1rem;
                padding: 1.75rem 2.5rem;
                border-radius: 0.5rem;
                border: none;
                z-index: 1;
                position: relative;
                display: inline-block;
                width: 13rem;

                &:after {
                    inset: 0;
                    z-index: -1;
                    content: "";
                    border-radius: 0.5rem;
                    position: absolute;
                    transform: scaleX(1);
                    transition: 0.5s;
                    background-color: $primary-color !important;
                }

                &:hover {
                    &:after {
                        transform: scaleX(0);
                    }
                }
            }
        }
    }
}

// Swal Css

.PhoneInput {
    border: none;
    box-shadow: none;
    border-bottom: 0.2rem solid #d3d3d3;
    border-radius: 0;
    font-size: 1.8rem;
    height: 5.6rem;
    padding: 0 0 0 4rem;
    color: $black-color;
    width: 100%;

    .PhoneInputInput {
        border: none;

        &:focus-visible {
            border: none !important;
            outline: none !important;
        }
    }
}

// Header Css
.hmg-header {
    .full-container {
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;
    }
}

// Header Css